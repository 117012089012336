import { Check, Copy, ThumbsUp, ThumbsDown, MessageSquare } from 'lucide-react'

import { JSONValue, Message } from 'ai'
import Image from 'next/image'
import { Button } from '../button'
import ChatAvatar from './chat-avatar'
import Markdown from './markdown'
import { useCopyToClipboard } from './use-copy-to-clipboard'
import { useState } from 'react'
import { postFeedback } from '@/app/api'
import CommentPopup from '@/app/components/ui/comment-popup'
import UploadFilePreview from '@/app/components/ui/upload-file-preview'

interface ChatMessageImageData {
  type: 'image_url'
  image_url: {
    url: string
  }
}

// This component will parse message data and render the appropriate UI.
function ChatMessageData({ messageData }: { messageData: JSONValue }) {
  const { image_url, type } = messageData as unknown as ChatMessageImageData
  if (type === 'image_url') {
    return (
      <div className="max-w-[200px] rounded-md shadow-md">
        <Image
          src={image_url.url}
          width={0}
          height={0}
          sizes="100vw"
          style={{ width: '100%', height: 'auto' }}
          alt=""
        />
      </div>
    )
  }
  return null
}

export default function ChatMessage(chatMessage: Message) {
  const { isCopied, copyToClipboard } = useCopyToClipboard({ timeout: 2000 })
  const [score, setScore] = useState<-1 | 1 | 0>(0)
  const [showCommentInput, setShowCommentInput] = useState(false)

  const sendFeedback = (clickedScore: number, submittedComment?: string) => {
    postFeedback({
      traceId: chatMessage.id,
      score: clickedScore,
      comment: submittedComment,
    })
  }

  const handleThumbsUpClick = () => {
    setScore(1)
    sendFeedback(1)
  }

  const handleThumbsDownClick = () => {
    setScore(-1)
    sendFeedback(-1)
  }

  const handleSubmitComment = (content: string) => {
    sendFeedback(score, content)
    setShowCommentInput(false)
  }

  const buttonClassName = 'h-8 w-8 opacity-50 hover:opacity-100'
  const buttonSelectedClassName = 'opacity-100 border border-gray-400 shadow'
  const iconClassName = 'h-5 w-5'

  return (
    <div className="flex items-start gap-4 pr-5 pt-5">
      <ChatAvatar role={chatMessage.role} />
      <div className="group flex flex-1 justify-between gap-2">
        <div className="md:text-md flex-1 space-y-1 text-sm">
          {chatMessage.data && (
            <ChatMessageData messageData={chatMessage.data} />
          )}
          <Markdown content={chatMessage.content} />
          {chatMessage.tool_call_id && (
            <div className="text-xs text-slate-600">
              {chatMessage.tool_call_id}
            </div>
          )}
          {chatMessage.experimental_attachments &&
            Array.from(chatMessage.experimental_attachments).map(
              (attachment) => (
                <UploadFilePreview
                  key={attachment.name}
                  attachment={attachment}
                />
              ),
            )}
        </div>
        <div className="flex flex-col items-end justify-between space-y-2">
          <Button
            onClick={() => copyToClipboard(chatMessage.content)}
            size="icon"
            variant="ghost"
            className={buttonClassName}
          >
            {isCopied ? (
              <Check className={iconClassName} />
            ) : (
              <Copy className={iconClassName} />
            )}
          </Button>
          <div>
            <CommentPopup
              onSubmit={handleSubmitComment}
              isOpen={showCommentInput}
              onClose={() => {
                setShowCommentInput(false)
              }}
            />
            {chatMessage.role !== 'user' && (
              <div className="flex flex-col md:flex-row">
                <Button
                  variant="ghost"
                  size={'icon'}
                  className={`${buttonClassName} ${score === 1 ? buttonSelectedClassName : ''}`}
                  onClick={handleThumbsUpClick}
                >
                  <ThumbsUp className={iconClassName} />
                </Button>
                <Button
                  variant="ghost"
                  size={'icon'}
                  className={`${buttonClassName} ${score === -1 ? buttonSelectedClassName : ''}`}
                  onClick={handleThumbsDownClick}
                >
                  <ThumbsDown className={iconClassName} />
                </Button>
                <Button
                  variant="ghost"
                  size={'icon'}
                  className={buttonClassName}
                  onClick={() => setShowCommentInput(!showCommentInput)}
                >
                  <MessageSquare className={iconClassName} />
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
