import React, { useState, useRef } from 'react'
import { Button } from '@/app/components/ui/button'

interface CommentPopupProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (comment: string) => void
}

export default function CommentPopup({
  isOpen,
  onClose,
  onSubmit,
}: CommentPopupProps) {
  const [comment, setComment] = useState('')
  const popupRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (
      isOpen &&
      popupRef.current &&
      !popupRef.current.contains(event.target as Node)
    ) {
      onClose()
    }
  }
  // close popup on click outside
  document.addEventListener('mousedown', handleClickOutside)

  if (!isOpen) return null

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30">
      <div
        className="w-full max-w-md rounded-lg bg-white p-4 shadow-lg"
        ref={popupRef}
      >
        <textarea
          className="w-full resize-none rounded border border-gray-300 p-2"
          rows={4}
          value={comment}
          placeholder="Comment on the tutorbot response..."
          onChange={(e) => setComment(e.target.value)}
          onKeyDown={(e) => {
            if (e.key == 'Enter') {
              onSubmit(comment)
              setComment('')
              onClose()
            }
          }}
        ></textarea>
        <div className="mt-4 flex justify-end space-x-2">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              onSubmit(comment)
              setComment('')
              onClose()
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  )
}
