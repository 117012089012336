'use client'

import { useEffect, useMemo, useRef, useState } from 'react'
import { Message, useChat } from 'ai/react'
import { ChatInput, ChatMessages } from './ui/chat'
import { ChatRequestOptions, generateId } from 'ai'
import { usePathname } from 'next/navigation'
import { useChatContext } from '@/context/chat-context'
import { insertDataIntoMessages } from '@/app/components/transform'
import { revalidatePath } from 'next/cache'

export default function ChatSection({
  initialChatId,
  initialMessages,
}: {
  initialChatId?: string
  initialMessages: Message[]
}) {
  const {
    chatModel,
    disabled,
    refreshUsage,
    refreshChats,
    chatId,
    setChatId,
    chatMode,
    chatModes,
    withThinkingMode,
  } = useChatContext()

  useEffect(() => {
    if (initialChatId) setChatId(initialChatId)
  }, [initialChatId, setChatId])

  const pathname = usePathname()

  const controlledMessages = useRef<Message[]>([])
  const [chatMessage, setChatMessage] = useState('')
  // we keep track of latest trace id -> on new message push data with id into array
  // each trace id is used to submit user feedback related to that given trace to langfuse
  const latestTraceId = useRef<string | null>(null)

  const {
    messages,
    input,
    isLoading,
    handleSubmit,
    handleInputChange,
    reload,
    stop,
    data,
    setMessages,
  } = useChat({
    api: `${process.env.NEXT_PUBLIC_CHAT_API}/chat`,
    // https://github.com/vercel/ai/issues/1316#issuecomment-2046587533
    streamProtocol: 'text',
    // streamMode: 'text',
    sendExtraMessageFields: true,
    body: {
      chatId,
      chatMode,
      withThinkingMode,
      modelValue: chatModel.value,
    },
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    onResponse(response) {
      // get the latest traceId to use for feedback
      latestTraceId.current = response.headers.get('X-Trace-Id')
      // refresh chat history
      refreshChats()
    },
    onFinish(message) {
      // update new chat path to the correct saved session path after the first message
      // wait 1 sec to update to ensure the session exists in db.. should do smarter in the future
      const chatRoute = `/${chatId}`
      if (pathname != chatRoute) {
        // pushState instead of replace to not lose the text the user is typing
        revalidatePath(`/${chatId}`)
        window.history.pushState(null, '', chatRoute)
      }
      // update controlledMessages
      controlledMessages.current.push({
        ...message,
        id: latestTraceId.current ?? message.id,
        tool_call_id: chatModel.name,
      })

      // update usage in 10 seconds
      setTimeout(() => {
        refreshUsage()
      }, 10000)

      // update "ai" messages state
      setMessages(controlledMessages.current)
    },
  })

  useEffect(() => {
    controlledMessages.current = [...initialMessages]
    setMessages([...controlledMessages.current])
  }, [chatId, setMessages, initialMessages])

  const transformedMessages = useMemo(() => {
    return insertDataIntoMessages(messages, data)
  }, [messages, data])

  const handleChatInputChange = (
    e:
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLInputElement>,
  ) => {
    setChatMessage(e.target.value)
    handleInputChange(e)
  }

  const handleChatSubmit = (
    e: React.FormEvent<HTMLFormElement>,
    ops?: ChatRequestOptions,
  ) => {
    // TODO: there is some kind of bug that adds two identical new messages if we have attachments, but then for following requests the duplication is removed
    // no impact right now, but technically this is not the correct behaviour
    handleSubmit(e, ops)

    // TODO: consider how to persist files in chat history
    const newMessage: Message = {
      id: generateId(),
      role: 'user',
      content: chatMessage,
      // @ts-ignore
      experimental_attachments: ops?.experimental_attachments,
    }
    controlledMessages.current.push(newMessage)
    setChatMessage('')
  }

  const allowMultiModal = process.env.NEXT_PUBLIC_MULTIMODAL === 'true'

  return (
    <>
      <ChatMessages
        messages={transformedMessages}
        isLoading={isLoading}
        reload={reload}
        stop={stop}
      />
      <ChatInput
        input={input}
        handleSubmit={handleChatSubmit}
        handleInputChange={handleChatInputChange}
        isLoading={isLoading}
        multiModal={allowMultiModal}
        isLimitExceeded={disabled}
        chatModes={chatModes}
      />
    </>
  )
}
