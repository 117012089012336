import { Loader2 } from 'lucide-react'
import { useEffect, useRef } from 'react'

import ChatActions from './chat-actions'
import ChatMessage from './chat-message'
import { ChatHandler } from './chat.interface'

export default function ChatMessages(
  props: Pick<ChatHandler, 'messages' | 'isLoading' | 'reload' | 'stop'>,
) {
  const scrollableChatContainerRef = useRef<HTMLDivElement>(null)
  const messageLength = props.messages.length
  const lastMessage = props.messages[messageLength - 1]

  const scrollToBottom = () => {
    if (scrollableChatContainerRef.current) {
      scrollableChatContainerRef.current.scrollTop =
        scrollableChatContainerRef.current.scrollHeight
    }
  }

  const isUserScrolledToBottom = () => {
    if (!scrollableChatContainerRef.current) return false

    const { scrollTop, clientHeight, scrollHeight } =
      scrollableChatContainerRef.current
    // determine a threshold for how close a user has to be to the bottom in pixels to be considered at the bottom
    const threshold = 100
    return scrollTop + clientHeight >= scrollHeight - threshold
  }

  const isLastMessageFromAssistant =
    messageLength > 0 && lastMessage?.role !== 'user'
  const showReload =
    props.reload && !props.isLoading && isLastMessageFromAssistant
  const showStop = props.stop && props.isLoading

  // `isPending` indicate
  // that stream response is not yet received from the server,
  // so we show a loading indicator to give a better UX.
  const isPending = props.isLoading && !isLastMessageFromAssistant

  useEffect(() => {
    if (isUserScrolledToBottom()) {
      scrollToBottom()
    }
  }, [lastMessage])
  useEffect(() => {
    scrollToBottom()
  }, [showStop])

  return (
    <div
      className="w-full flex-grow overflow-y-auto rounded-xl bg-white p-4 pb-0 shadow-xl"
      ref={scrollableChatContainerRef}
    >
      <div className="flex flex-col gap-5 divide-y pb-4">
        {props.messages.map((m) => (
          <ChatMessage key={m.id} {...m} />
        ))}
        {isPending && (
          <div className="flex items-center justify-center pt-10">
            <Loader2 className="h-4 w-4 animate-spin" />
          </div>
        )}
      </div>
      <div className="flex justify-end py-4">
        <ChatActions
          reload={props.reload}
          stop={props.stop}
          showReload={showReload}
          showStop={showStop}
        />
      </div>
    </div>
  )
}
