import { FC, memo, PropsWithChildren, useState } from 'react'
import ReactMarkdown, { Options } from 'react-markdown'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import rehypeSanitize from 'rehype-sanitize'
import rehypeExternalLinks from 'rehype-external-links'
import { defaultSchema } from 'rehype-sanitize'
import remarkGfm from 'remark-gfm'
import 'katex/dist/katex.min.css'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '../collapsible'
import { ChevronsUpDown } from 'lucide-react'
import { Button } from '../button'

const markdownStyles = {
  ul: {
    listStyleType: 'disc',
    marginLeft: '20px',
  },
  ol: {
    listStyleType: 'decimal',
    marginLeft: '20px',
  },
  li: {
    marginBottom: '5px',
  },
}

const ThinkingBlock = ({ children }: PropsWithChildren) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Collapsible open={isOpen} onOpenChange={setIsOpen}>
      <CollapsibleTrigger asChild>
        <Button
          variant="ghost"
          size="sm"
          className="font-mono text-xs font-bold"
        >
          <ChevronsUpDown className="mr-2 h-4 w-4" />
          <span>Thinking</span>
        </Button>
      </CollapsibleTrigger>
      <CollapsibleContent className="text-wrap p-2">
        {children}
      </CollapsibleContent>
    </Collapsible>
  )
}

const MemoizedReactMarkdown: FC<Options> = memo(
  ReactMarkdown,
  (prevProps, nextProps) =>
    prevProps.children === nextProps.children &&
    prevProps.className === nextProps.className,
)

export default function Markdown({ content }: { content: string }) {
  return (
    <MemoizedReactMarkdown
      className="prose prose-sm max-w-none break-words leading-snug prose-headings:mb-2 prose-headings:mt-2 prose-h1:text-xl prose-h2:text-lg prose-h3:text-base prose-code:font-mono prose-code:text-gray-800 prose-code:before:content-none prose-code:after:content-none prose-pre:mb-4 prose-pre:overflow-x-auto prose-pre:rounded-lg prose-pre:border prose-pre:border-slate-200 prose-pre:bg-gray-50 prose-pre:p-1 prose-pre:text-slate-700 prose-ol:m-0 prose-ul:m-0"
      remarkPlugins={[remarkMath, remarkGfm]}
      rehypePlugins={[
        [
          rehypeSanitize,
          {
            ...defaultSchema,
            tagNames: [
              ...(defaultSchema.tagNames || []),
              'h1',
              'h2',
              'h3',
              'h4',
              'h5',
              'h6',
              'pre',
              'code',
            ],
            attributes: {
              ...defaultSchema.attributes,
              div: [
                ...(defaultSchema?.attributes?.div || []),
                ['className', 'math', 'math-display'],
              ],
              span: [
                ...(defaultSchema?.attributes?.span || []),
                ['className', 'math', 'math-inline'],
              ],
              a: [
                ...(defaultSchema?.attributes?.a || []),
                ['className', 'href', 'target', 'rel'],
              ],
            },
          },
        ],
        [rehypeKatex, { output: 'mathml' }],
        [
          rehypeExternalLinks,
          {
            target: '_blank',
            rel: ['noopener', 'noreferrer', 'nofollow'],
          },
        ],
      ]}
      components={{
        p({ children }) {
          return <p className="mb-2 last:mb-0">{children}</p>
        },
        a({ node, ...props }) {
          return (
            <a
              style={{ color: 'blue', textDecoration: 'underline' }}
              {...props}
            />
          )
        },
        ul({ children }) {
          return <ul style={markdownStyles.ul}>{children}</ul>
        },
        ol({ children }) {
          return <ol style={markdownStyles.ol}>{children}</ol>
        },
        li({ children }) {
          return <li style={markdownStyles.li}>{children}</li>
        },
        code({ node, ...props }) {
          return <ThinkingBlock>{props.children}</ThinkingBlock>
        },
      }}
    >
      {content}
    </MemoizedReactMarkdown>
  )
}
