import { ToggleGroup, ToggleGroupItem } from '@/app/components/ui/toggle-group'
import { useChatContext } from '@/context/chat-context'
import { Switch } from './ui/switch'

export function ChatModeToggleGroup() {
  const { chatModes, chatMode, setChatMode } = useChatContext()

  const handleToggle = (mode: string) => {
    // Only update chatMode if the new mode is different
    if (mode && mode !== chatMode) {
      setChatMode(mode)
    }
  }

  return (
    <ToggleGroup
      type="single"
      variant="outline"
      value={chatMode}
      onValueChange={handleToggle}
      size="xs"
      className="justify-start text-xs"
    >
      {chatModes.map((mode) => (
        <ToggleGroupItem
          key={mode}
          value={mode}
          aria-label={`Toggle ${mode} mode`}
        >
          {mode}
        </ToggleGroupItem>
      ))}
    </ToggleGroup>
  )
}

export function ThinkingModeSwitch() {
  const { withThinkingMode, setWithThinkingMode, chatModel } = useChatContext()

  const isIncompatible = chatModel.thinking == 'none'

  return (
    <Switch
      disabled={isIncompatible}
      checked={isIncompatible ? false : withThinkingMode}
      onCheckedChange={setWithThinkingMode}
    />
  )
}
